import React from 'react';
import { IEdge, Zone } from '../../../../../../shared-types';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { getState, togglePipeLabels, useDesignStore } from '../../state';
import { changeEdges } from '../../state/changeEdges';
import { deleteEdge } from '../../state/deleteEdge';
import { CheckboxAction } from './CheckboxAction';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const PipesLayer = ({ open, onOpen }) => {
  const showPipeLabels = useDesignStore((state) => state.showPipeLabels);
  const valveElIDs = useDesignStore((state) =>
    state.zones.reduce((acc: string[], zone: Zone) => {
      return [...acc, zone.valve, zone.valveInputFitting];
    }, []),
  );
  const edges = useDesignStore((state) => state.edges);
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.EDGES,
  );
  const pipeDict = getState().pipeProducts.reduce((acc, pipe) => {
    acc[pipe.uuid] = pipe;
    return acc;
  }, {});
  const toggleShowLabels = () => {
    togglePipeLabels();
  };
  const toggleChildVisibility = (edge: IEdge) => {
    changeEdges([{ ...edge, visible: !edge.visible }]);
  };
  const toggleChildLock = (edge: IEdge) => {
    changeEdges([{ ...edge, locked: !edge.locked }]);
  };
  const getPipeTitle = (edge: IEdge) => {
    const pipe = pipeDict[edge.pipe];
    if (!pipe) {
      console.error('select pipes');
      return '';
    }
    let title = `${edge.isLateral ? 'lateral' : 'main'}: ${pipe.name}`;
    if (valveElIDs.includes(edge.source) || valveElIDs.includes(edge.target)) {
      title += ' (valve)';
    }
    return title;
  };
  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Pipes (${edges.length})`}
      open={open}
      onOpen={onOpen}
      actions={
        <>
          <CheckboxAction
            title="Labels"
            onClick={toggleShowLabels}
            checked={showPipeLabels}
            tooltip="Shows labels for non-default pipes"
          />
        </>
      }
      childLayer={edges.map((edge) => (
        <LayerChild
          key={edge.uuid}
          visible={edge.visible}
          locked={edge.locked}
          title={getPipeTitle(edge)}
          clickToggle={() => toggleChildVisibility(edge)}
          clickLock={() => toggleChildLock(edge)}
          clickDelete={() => deleteEdge(edge.uuid)}
          layerLocked={layerLocked}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(PipesLayer);
